import BaseBean from "@/utils/BaseBean";

export interface ISysTitleListDataObj {
    utilInst:SysTitleListUtil
    refMap:Map<string,any>
    pageListRef:any
    corpTreeData: Array<any>
    pageList: any
}

export default class SysTitleListUtil extends BaseBean{
    public dataObj:ISysTitleListDataObj

    constructor(proxy:any,dataObj:ISysTitleListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}