import BaseBean from "@/utils/BaseBean";

export interface ISysTitleCardDataObj {
    utilInst:SysTitleCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    corpTreeData:Array<any>
    compParams: any
    form:any
    rules:TFormRule
}
export default class SysTitleCardUtil extends BaseBean{
    public dataObj:ISysTitleCardDataObj;

    constructor(proxy:any,dataObj:ISysTitleCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        //this.dataObj.networkData=await this.utils.Api.buildNetworkData();
    }
}