import SysTitleCard from './SysTitleCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide} from 'vue';
import SysTitleListUtil,{ISysTitleListDataObj} from './sysTitleListUtil';
export default defineComponent ({
    name: 'SysTitleList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:ISysTitleListDataObj=reactive<ISysTitleListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            corpTreeData:[],
            pageList: {
                queryParam: {},
                modelComp: SysTitleCard,
                modelMethod: utils.Api.buildUrl('/sysTitle/pageData')
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new SysTitleListUtil(proxy,dataObj);
        })
        onMounted(async ()=>{
            dataObj.corpTreeData = await utils.Api.corpTreeData();
        })
        //---------------------------computed------------

        //cascader
        const cascaderData=computed(()=>{
            return (params:any) => {
                if(params && 'corpId'==params.comboId){
                    return dataObj.corpTreeData;
                }
            }
        })
        return{
            ...toRefs(dataObj),cascaderData
        }
    }
});